import loginPageIconImport from "../assets/login.svg";
import registerPageIconImport from "../assets/register.svg";

export const adminPath = "monqez_@dminn";
export const teachersubadminPath = "monqez_te@cher";
export const domainName = "elmonqez";
export const isManualPayment = false;
export const isCouponable = true;
export const isMultiYear = true;
export const isCodes = false;
export const isBtns = false;
export const isLoginTokens = true;
export const profileStatistics = true;

export const enableAutoLogin = false;

export const isFreeTrialAvailable = false;

export const isRegisterationMiddleName = true;
export const isRegisterationForthName = true;
export const isRegisterByDepartments = true;
export const isRegisterationFatherJob = false;
export const isRegisterationGender = false;

export const adminPanelColor = "blueMonq";
export const headerSectionColor = "blueMonq";
export const navbarIconColor = "text-blueMonq-500";
export const navbarDisclosureColor = "blueMonq";
export const isCenters = false;
export const isCourseLinks = true;
export const isCoursesRenamed = false;
export const isSectionRenamed = false;

export const progressBarColor = "blueMonq";
export const loadingBarColor = "sky";

export const isBunny = false;

export const isPlatformSubscription = false;
export const isTimedManualSubscription = false;

export const isPrepaidSystem = false;
export const prepaidInfoColor = "blueMonq";

export const autoChangeRandomNames = false;

export const navThemeRTL = true;

export const registerPageIcon = registerPageIconImport;
export const loginPageIcon = loginPageIconImport;
export const loginPageColor = "blueMonq";
export const registerPageColor = "earthYellow";

export const isAnalytics = false;

export const isMultiAdmin = true;

export const isSubjectSubscriptionable = true;
export const isAccountCreationRequest = false;

export const showExamStatisticsTable = true;
export const showVideoStatisticsTable = true;
export const isUserStatisticsTable = true;

export const isAdminToAdminNote = false;
export const isAdminAbleToChangeUsersYear = false;

export const isOtpEnabled = false;

export const isWalletEnabled = false;

export const isStudyPlannerEnabled = false;

export const isOnlySubscribeFromWallet = false;

export const isCenterUsers = false;

export const isForgetPassword = false;

export const isAdminUsers = false;

export const isSubscribeBySubjectTeacher = false;

export const isMigrationFromInsertAuto = false;
export const isChargeInsertAuto = false;
export const isInsertAutoTypes = false;

export const isCourseRenamedToMonthlySubscriptions = false;

export const isCommunity = false;

export const isCustomUserExamLimit = false;
export const isCustomUserVideoLimit = false;

export const isCenterApi = false;

export const isLoginAsUser = false;

export const isEssayQuestions = true;

export const isAdminCourseStatistics = false;

export const isSearch = false;

export const isPinnedCourse = true;

export const isUnassignedSubscription = false;

export const isStoreLocatorAvailable = false;

export const isYoutubeVideoButton = false;

export const isAddCenterUser = false;

export const isNavBarNotification = false;
