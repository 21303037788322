// import React from 'react';
import { ReactComponent as CardHeaderDecore } from "../../../assets/monqez-assets/cardHeaderDecor.svg";
const cardData = [
    {
        title: "ابتدائي واعدادي",
        description:
            " يعني تركيز كبير على التأسيس القوي في المهارات الأساسية من الصف الأول حتى الصف الثالث الإعدادي.",
    },
    {
        title: "تعلّم واستمتع",
        description:
            "وفرنا لك لوحة تحكم شاملة لمتابعة كل تفاصيل تعلمك، بما يشمل المناهج، الكويزات، الاشتراكات.",
    },
    {
        title: "تأسيس صح",
        description:
            "هدفنا مساعدتك في بناء قاعدة تعليمية قوية في بداية رحلتك، مع متابعة مستمرة لمستواك لضمان استعدادك.",
    },
];

const Card = () => {
    return (
        <div className="w-full h-full flex flex-wrap justify-evenly gap-6 md:gap-3 relative z-10">
            {/* <div className="w-[1px] h-full md:h-[1px] bg-blueMonq-950 dark:bg-blueMonq-50 absolute top-0 bottom-0 mx-auto md:mx-0 md:left-0 md:right-0 md:top-1/2 " /> */}
            {cardData.map((card, index) => (
                <div
                    key={index}
                    className={`${index == 0 ? "mt-2" : index == 1 ? "mt-6" : "mt-10"}`}
                >
                    <div className="h-[282px]  w-[300px] relative  overflow-hidden bg-blueMonq-300 smooth rounded-2xl  dark:bg-blueMonq-800 border border-solid border-black flex flex-col space-y-10 items-center px-2 py-5">
                        <div className="relative">
                            <div className="flex-center-both">
                                <CardHeaderDecore className="fill-blueMonq-100 dark:fill-earthYellow-500/45 smooth absolute w-[100px]  top-3 -rotate-1" />
                            </div>
                            <h1 className="font-expo text-center text-2xl text-blueMonq-950 dark:text-blueMonq-50 smooth z-10">
                                {card.title}
                            </h1>
                        </div>
                        <div className="pt-14">
                            <p className="text-blueMonq-950 px-2 text-center dark:text-blueMonq-50 smooth text-[16px]">
                                {card.description}
                            </p>
                        </div>
                        <div className="w-full h-3 bg-earthYellow-200 dark:bg-earthYellow-500 smooth absolute bottom-0 mx-auto " />
                        <div className="w-full h-3 bg-earthYellow-200 dark:bg-earthYellow-500 smooth absolute -top-10 mx-auto " />
                    </div>
                </div>
            ))}
        </div>
    );
};

export default Card;
